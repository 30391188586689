import currency from 'currency.js';
import ServiceError from '@/app/config/providers/error';

const Format = () => {
  return {
    cpfCnpj(number) {
      if (!number) return null;

      const digitsOnly = number.replace(/\D/g, '');
      if (digitsOnly.length === 11) return digitsOnly.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      if (digitsOnly.length === 14) return digitsOnly.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');

      throw ServiceError('Invalid number');
    },
    realMoney(value, fromCents = true, symbol = 'R$ ', negativePattern = '-!#') {
      return value === undefined ? '' :
        currency(value, {
          fromCents,
          precision: 2,
          separator: '.',
          decimal: ',',
          symbol,
          negativePattern
        }).format();
    },
    onlyNumberOfString(valueString, isNegative = true) {
      if (valueString && typeof valueString === 'string') {
        if (isNegative) {
          const numericValue = valueString.replace(/[^\d-]+/g, '');
          return `${ numericValue }`;
        } else {
          const numericValue = valueString.replace(/[^\d]+/g, '');
          return `${ numericValue }`;
        }
      }
      return '0'
    },
    onlyNumberOfStringWithComma(string) {
      if (string && typeof string === 'string') {
        return string.replace(/^(-)|[^0-9.,]+/g, '');
      }
      return '';
    },
    discountStr(discount) {
      if (discount) {
        return discount.unit == 'percentual' ? `${ discount.value }%` : this.realMoney(discount.value);
      }
      return '';
    },
    multiplePercentageDiscountStr(discount) {
      if (discount) {
        return `${ parseFloat(discount) * 100 }%`;
      }
      return '';
    },
    removeSpecialCharacters(string) {
      return string.replace(/[^\w\s]/gi, '')
    },
    addressConcat(address) {
      if (address) {
        return `${ address.street }, ${ address.number } - ${ address.district }${ address.complement ? ', ' + address.complement : '' }, ${ address.city } - ${ address.state }, ${ address.zipCode }`
      }
      return '';
    },
    firstName(name = '') {
      return name.split(' ')[0]
    },
    noSpaces(string = '') {
      return string.replace(/ /g, '')
    },
    replaceSpecialCharactersString(inputString, to = '_') {
      let replacedString = this.removeSpecialCharacters(inputString);
      replacedString = replacedString.replace(/[.,_\- ]/g, to);
      replacedString = replacedString.replace(/_+/g, to);

      return replacedString.replace(/^_+|_+$/g, '');
    },
    stringToIntRealMoney(valueString) {
      if (valueString && typeof valueString === 'string') {
        const numericValue = valueString.replace(/[^\d]+/g, '')
        return parseInt(numericValue);
      }
      return 0
    }
  };
};

export default Format();
